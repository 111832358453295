/* 
 * CUSTOM SLIDESHARE ICONS 
 */

 @font-face {
  font-family: "slideshare-icons";
  src: url("../fonts/slideshare-icons.eot");
  src: url("../fonts/slideshare-icons.eot#iefix")
      format("embedded-opentype"),
    url("../fonts/slideshare-icons.woff") format("woff"),
    url("../fonts/slideshare-icons.ttf") format("truetype"),
    url("../fonts/slideshare-icons.svg#slideshare-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; /* https://stackoverflow.com/questions/49461308/correct-font-display-value-for-icon-fonts */
}

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'slideshare-icons';
      src: url('../font/slideshare-icons.svg?88780013#slideshare-icons') format('svg');
    }
  }
  */
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "slideshare-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-add-file:before {
  content: "\e800";
}
.icon-add-chat:before {
  content: "\e801";
}
.icon-add-user:before {
  content: "\e802";
}
.icon-approve-chat:before {
  content: "\e803";
}
.icon-approve-file:before {
  content: "\e804";
}
.icon-approved-mail:before {
  content: "\e805";
}
.icon-approved-user:before {
  content: "\e806";
}
.icon-arrow-left:before {
  content: "\e807";
}
.icon-arrow-right:before {
  content: "\e808";
}
.icon-badge:before {
  content: "\e809";
}
.icon-block-mail:before {
  content: "\e80a";
}
.icon-block:before {
  content: "\e80b";
}
.icon-bookmark-filled:before {
  content: "\e870";
}
.icon-bookmark:before {
  content: "\e80d";
}
.icon-brightness:before {
  content: "\e80e";
}
.icon-calendar:before {
  content: "\e80f";
}
.icon-cancel-large:before {
  content: "\e810";
}
.icon-cancel:before {
  content: "\e811";
}
.icon-caret-down:before {
  content: "\e812";
}
.icon-caret-up:before {
  content: "\e813";
}
.icon-chart-bar:before {
  content: "\e814";
}
.icon-chat-alt:before {
  content: "\e815";
}
.icon-chat-bubble-blank:before {
  content: "\e816";
}
.icon-chat-bubble:before {
  content: "\e817";
}
.icon-chat-info:before {
  content: "\e818";
}
.icon-chat:before {
  content: "\e819";
}
.icon-check-circle:before {
  content: "\e81a";
}
.icon-check:before {
  content: "\e81b";
}
.icon-chevron-down:before {
  content: "\e81c";
}
.icon-chevron-left-large:before {
  content: "\e81d";
}
.icon-chevron-left:before {
  content: "\e81e";
}
.icon-chevron-right-large:before {
  content: "\e81f";
}
.icon-chevron-right:before {
  content: "\e820";
}
.icon-chevron-up:before {
  content: "\e821";
}
.icon-clock:before {
  content: "\e822";
}
.icon-compress:before {
  content: "\e823";
}
.icon-cone:before {
  content: "\e824";
}
.icon-credit-card:before {
  content: "\e825";
}
.icon-danger-filled:before {
  content: "\e826";
}
.icon-danger:before {
  content: "\e827";
}
.icon-delete-user:before {
  content: "\e828";
}
.icon-dislike:before {
  content: "\e829";
}
.icon-document-blank:before {
  content: "\e82a";
}
.icon-document:before {
  content: "\e82b";
}
.icon-download:before {
  content: "\e82c";
}
.icon-ellipsis-h:before {
  content: "\e82d";
}
.icon-ellipsis-v:before {
  content: "\e82e";
}
.icon-equalizer:before {
  content: "\e82f";
}
.icon-exclamation-circle-filled:before {
  content: "\e830";
}
.icon-exclamation-circle:before {
  content: "\e831";
}
.icon-external-link:before {
  content: "\e832";
}
.icon-facebook:before {
  content: "\e833";
}
.icon-filters:before {
  content: "\e834";
}
.icon-flag:before {
  content: "\e835";
}
.icon-focus:before {
  content: "\e836";
}
.icon-folder:before {
  content: "\e837";
}
.icon-google:before {
  content: "\e838";
}
.icon-graduation-hat:before {
  content: "\e839";
}
.icon-grid:before {
  content: "\e83a";
}
.icon-happy-face:before {
  content: "\e83b";
}
.icon-headphone:before {
  content: "\e83c";
}
.icon-heart-filled:before {
  content: "\e83d";
}
.icon-heart:before {
  content: "\e83e";
}
.icon-home:before {
  content: "\e83f";
}
.icon-like:before {
  content: "\e840";
}
.icon-link:before {
  content: "\e841";
}
.icon-linkedin:before {
  content: "\e842";
}
.icon-location:before {
  content: "\e843";
}
.icon-locked:before {
  content: "\e844";
}
.icon-low-volume:before {
  content: "\e845";
}
.icon-medical-chat:before {
  content: "\e846";
}
.icon-megaphone:before {
  content: "\e847";
}
.icon-message:before {
  content: "\e848";
}
.icon-microphone:before {
  content: "\e849";
}
.icon-mobile:before {
  content: "\e84a";
}
.icon-mute:before {
  content: "\e84b";
}
.icon-not-visible:before {
  content: "\e84c";
}
.icon-notes:before {
  content: "\e84d";
}
.icon-open-book:before {
  content: "\e84e";
}
.icon-pencil:before {
  content: "\e84f";
}
.icon-play:before { 
  content: '\e872'; 
}
.icon-plus:before {
  content: "\e850";
}
.icon-printer:before {
  content: "\e851";
}
.icon-redo:before {
  content: "\e852";
}
.icon-reload:before {
  content: "\e853";
}
.icon-remove-user:before {
  content: "\e854";
}
.icon-rss-feed:before {
  content: "\e855";
}
.icon-sad-face:before {
  content: "\e856";
}
.icon-save:before {
  content: '\e873';
}
.icon-screen:before {
  content: "\e857";
}
.icon-scribd:before {
  content: "\e858";
}
.icon-search:before {
  content: "\e859";
}
.icon-settings:before {
  content: "\e85a";
}
.icon-share:before {
  content: "\e85b";
}
.icon-smiley-face:before {
  content: "\e85c";
}
.icon-sort:before {
  content: "\e80c";
}
.icon-star-filled:before {
  content: "\e85d";
}
.icon-star-half-filled:before {
  content: "\e85e";
}
.icon-star:before {
  content: "\e85f";
}
.icon-tag:before {
  content: "\e860";
}
.icon-toggle-left:before {
  content: "\e861";
}
.icon-toggle-right:before {
  content: "\e862";
}
.icon-trash:before {
  content: "\e863";
}
.icon-trophy:before {
  content: "\e864";
}
.icon-twitter:before {
  content: "\e865";
}
.icon-typing-chat:before {
  content: "\e866";
}
.icon-uncheck:before {
  content: "\e867";
}
.icon-unlocked:before {
  content: "\e868";
}
.icon-upload:before {
  content: "\e869";
}
.icon-upset-face:before {
  content: "\e86a";
}
.icon-user-group:before {
  content: "\e86b";
}
.icon-user:before {
  content: "\e86c";
}
.icon-visible:before {
  content: "\e86d";
}
.icon-zoom-in:before {
  content: "\e86e";
}
.icon-zoom-out:before {
  content: "\e86f";
}
.icon-share-flat:before {
  content: "\e874";
}
.icon-resize-full:before {
  content: "\e875";
}
